var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"eaf08a32baa7a97771a7c1737ba4b0f52d72d1c5"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'

import { SENTRY_DSN } from './constants'

let environment = process.env.NEXT_PUBLIC_VERCEL_ENV

if (environment === 'production' && process.env.NEXT_PUBLIC_STAGING) {
  environment = 'staging'
}

Sentry.init({
  environment,
  enabled: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production',
  dsn: SENTRY_DSN,
  attachStacktrace: true,
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampler: samplingContext => {
    const operation = samplingContext.transactionContext?.op
    if (operation && operation.indexOf('upload') !== -1) {
      return 1
    }
    return 0.1
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Default is 250 which ends up trimming relevant error messages... with 5000
  // we should have more useful information
  maxValueLength: 5000,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),

  integrations: [new Sentry.BrowserTracing(), new ExtraErrorData()],
  ignoreErrors: [
    'Uncaught Error in snapshot listener: FirebaseError: [code=permission-denied]: Missing or insufficient permissions.',
    "Unable to access localStorage SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
    'This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API',
    'Analytics.js',
    'analytics.js',
    'ResizeObserver loop limit exceeded',
    'NotSupportedError: screen.orientation.lock() is not available on this device.',
    'ResizeObserver loop completed with undelivered notifications.',
    'antiFingerPrint is not defined',
    '@firebase/app-check: FirebaseError: AppCheck: ReCAPTCHA error. (appCheck/recaptcha-error).',
    '[ACII]: {"description":"HTML Canvas - WebGL Context",',
    'AppCheck',
    'Loading initial props canceled',
    'Non-Error promise rejection captured with value: undefined',

    // Hls.js worker fails to load likely because of a conflict with SWC minify.
    // Hls.js catches this and falls back to inline script, but they do report the error.
    // We can ignore it.
    "Unexpected token '.'. Expected an opening '(' before a function's parameter list.",
  ],
  allowUrls: [/https?:\/\/((cdn|app|demo)\.)?arcade\.software/],
  beforeSend: event => {
    const msg = event?.message || event.exception?.values?.[0]?.value || ''
    const isMobile = window.navigator.userAgent.toLowerCase().includes('mobile')

    if (isMobile) {
      // Ignore those errors when they come from mobile
      if (
        msg.includes('Operation is not supported') ||
        msg.includes('ScreenOrientation') ||
        msg.includes('screen.orientation') ||
        msg.includes('app-check') ||
        msg.includes('IDBDatabase') ||
        msg.includes('The document is sandboxed') ||
        msg.includes('Database deleted by request of the user') ||
        msg.includes('@firebase/firestore') ||
        msg.includes('Firebase: Error') ||
        msg.includes('createObjectStore') ||
        msg.includes('app/storage')
      ) {
        return null
      }
    }

    // Ignore errors from non-production environments
    if (
      process.env.NODE_ENV !== 'production' ||
      event?.environment !== 'production'
    ) {
      return null
    }

    if (
      msg.includes('appCheck') ||
      msg.includes('XMLHttpRequestProgressEvent') ||
      msg.includes('Failed to load Analytics.js') ||
      msg.includes('[object ProgressEvent]') ||
      msg.includes('TypeError: Load failed') ||
      msg.includes('The operation is insecure') ||
      msg.includes('Resource blocked by content blocker') ||
      msg.includes('Error loading https://cdn.arcade.software/scripts/') ||
      window.navigator.userAgent.toLowerCase().includes('headless')
    ) {
      return null
    }

    return event
  },
})
